import React, { useContext } from "react"
import WrapperRight from "../components/WrapperRight"
import { SearchJobContext } from "../context/SearchJobContext"
import { Seo } from "../components/seo"
import FilterResults from "../components/FilterResults"
import SearchJobResults from "../components/SearchJobResults"
import { SectionTitle } from "../components/styled"
const JobsSearch = props => {
  const dataFromURL = props.search
  const { allTag } = useContext(SearchJobContext)
  return (
    <>
      <Seo
        title={`検索結果: ${allTag} | メキシコの求人・転職・就職情報【QUICK GLOBAL MEXICO】`}
      />
      <section className="container jobs-listing">
        <div className="row">
          <div className="col-lg-9">
            <div className="jobs-listing__search">
              <SectionTitle>メキシコ求人・就職・転職情報</SectionTitle>
              <hr className="u-line-bottom" />
            </div>
            {dataFromURL.search ? (
              <SearchJobResults dataurl={dataFromURL} />
            ) : (
              <FilterResults dataurl={dataFromURL} />
            )}
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default JobsSearch
